<template>
  <layout :title="title" :loading="isLoading">
    <div class="d-flex align-center" slot="add" v-if="!isDisputeLetters">
      <dispute-letter-schedule-status-update />
      <add-manual-dispute-letter
        @add="fetchFaxes(options)"
        v-if="hasEdit('send-faxes') && isSendFaxAdmin"
      />
      <div>
        <add-button class="ml-3" @click="$router.push({ name: 'send-letters' })"
          >Add Dispute Letter</add-button
        >
      </div>
    </div>
    <dispute-letter-add-button
      slot="add"
      v-if="isDisputeLetters && hasEdit('send-faxes')"
    />
    <dispute-letters-filter
      :headers="headers"
      :items="list"
      ref="filter"
      @onUpdate="fetchFaxes(options)"
      @onFilterApply="onFilterApply"
    />
    <dynamic-table-view
      ref="table"
      :show-select="hasEdit('send-faxes') || hasDelete('send-faxes')"
      :server-items-length="total"
      :headers="headers"
      @onPageChange="fetchFaxes"
      v-model="selected"
      :loading="loading"
      :list="list"
    >
      <div slot="left" class="d-flex mt-2">
        <dispute-letter-bulk-schedule-update
          :items="selected"
          v-if="hasPermission('send-faxes', 'edit')"
          @fetchFaxes="fetchFaxes(options)"
        />
        <dispute-letter-bulk-sent-date-update
          :items="selected"
          v-if="hasPermission('send-faxes', 'edit')"
          @fetchFaxes="fetchFaxes(options)"
        />
        <error-button
          @click="deleteAllFax()"
          v-if="hasPermission('send-faxes', 'delete')"
          :disabled="!selected.length"
          tile
          >Delete</error-button
        >
      </div>
      <template v-slot:item.status="{ item, value }">
        <v-tooltip bottom :disabled="!isSendFaxAdmin || !getErrorMessage(item)">
          <template v-slot:activator="{ on, attrs }">
            <span
              class="font-weight-bold"
              v-bind="attrs"
              v-on="on"
              :class="getStatusColor(getStatus(item))"
              >{{
                (getStatus(item) || "").split(" ").join("&nbsp;&nbsp;")
              }}</span
            >
          </template>
          <span>{{ getErrorMessage(item) }}</span>
        </v-tooltip>
      </template>

      <template v-slot:item.customer_name="{ value, item }">
        <router-link
          class="text-decoration-none"
          :to="{ name: 'client-profile', params: { id: item.customer } }"
        >
          {{ value }}
        </router-link>
      </template>
      <template v-slot:item.template="{ value, item }">
        <v-tooltip bottom v-if="item.is_manual_fax != true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" color="primary">
              <v-icon> mdi-information-outline </v-icon>
            </v-btn>
          </template>
          <span>{{ value }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.schedule_name="{ item, value }">
        <div class="d-flex align-center">
          {{ value || "N/A" }}
          <dispute-letter-schedule-update
            :item="item"
            v-if="hasEdit('send-faxes') && item.is_manual_fax != true"
            @onScheduleChange="fetchFaxes(options)"
            :schedules="faxSchedules"
          />
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <dispute-letters-menu
          v-if="item.is_manual_fax != true"
          @showLoader="loading = true"
          @hideLoader="loading = false"
          :item="item"
          @fetchFaxes="fetchFaxes(options)"
        />
      </template>
    </dynamic-table-view>
  </layout>
</template>

<script>
import Layout from "@/components/ui/Layout.vue";
import DynamicTableView from "@/components/ui/DynamicTableView.vue";
import { getHeaders, getStartEndDate } from "@/assets/utils/common.utils";
import { mapDocs, transformDisputeLetters } from "@/assets/utils/doc.utils";
import {
  colFaxes,
  colFaxSchedules,
  colFaxTemplates,
  functions,
} from "@/assets/utils/firebase.utils";
import {
  getCountFromServer,
  getDocs,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import DisputeLettersFilter from "@/components/disputeLetters/DisputeLettersFilter.vue";
import { computed } from "vue";
import { faxStatusColors } from "@/assets/constants/fax.constants";
import DisputeLettersMenu from "@/components/disputeLetters/DisputeLettersMenu.vue";
import DisputeLetterScheduleUpdate from "@/components/disputeLetters/DisputeLetterScheduleUpdate.vue";
import DisputeLetterBulkScheduleUpdate from "@/components/disputeLetters/DisputeLetterBulkScheduleUpdate.vue";
import DisputeLetterBulkSentDateUpdate from "@/components/disputeLetters/DisputeLetterBulkSentDateUpdate.vue";
import ErrorButton from "@/components/ui/buttons/ErrorButton.vue";
import DisputeLetterScheduleStatusUpdate from "@/components/disputeLetters/DisputeLetterScheduleStatusUpdate.vue";
import { httpsCallable } from "firebase/functions";
import DisputeLetterAddButton from "@/components/disputeLetters/DisputeLetterAddButton.vue";
import AddButton from "@/components/ui/buttons/AddButton.vue";
import AddManualDisputeLetter from "@/components/disputeLetters/AddManualDisputeLetter.vue";
export default {
  components: {
    Layout,
    DynamicTableView,
    DisputeLettersFilter,
    DisputeLettersMenu,
    DisputeLetterScheduleUpdate,
    DisputeLetterBulkScheduleUpdate,
    DisputeLetterBulkSentDateUpdate,
    ErrorButton,
    DisputeLetterScheduleStatusUpdate,
    DisputeLetterAddButton,
    AddButton,
    AddManualDisputeLetter,
  },
  data() {
    return {
      selected: [],
      options: {},
      form: {},
      total: 20,
      list: [],
      loading: false,
      isLoading: true,
      faxTemplates: [],
      faxSchedules: [],
    };
  },
  provide() {
    return {
      faxTemplates: computed(() => this.faxTemplates),
      faxSchedules: computed(() => this.faxSchedules),
      isDisputeLetters: computed(() => this.isDisputeLetters),
    };
  },
  computed: {
    title() {
      return this.isDisputeLetters
        ? "Dispute Letters"
        : `Dispute Letters (${this.currentClient.name})`;
    },
    isDisputeLetters() {
      return this.$route.name == "dispute-letters";
    },
    headers() {
      let doc = {
        status: "Status",
        customer_name: "Client",
        fax_title: "Title",
        template: "",
        schedule_name: "Schedule",
        created_at: "Sent Date",
        updated_at: "Updated Date",
        actions: "Actions",
      };
      let sortable = [
        // "status",
        // "customer_name",
        // "fax_title",
        // "template",
        // "schedule",
        "created_at",
        // "updated_at",
      ];

      return getHeaders(doc).map((i) => {
        i.sortable = sortable.indexOf(i.value) != -1;
        return i;
      });
    },
    query() {
      let sortDesc = this.options.sortDesc[0];
      let sortBy = this.options.sortBy[0];
      let { status, schedule, customer, template, search } = this.form;
      let { start, end } = getStartEndDate(this.form);
      let terms = [];
      if (search) terms.push(search.trim().toLowerCase());

      let queryList = [];

      if (start && end) {
        queryList.push(orderBy("created_at", sortDesc ? "desc" : "asc"));
        queryList.push(where("created_at", ">=", start));
        queryList.push(where("created_at", "<=", end));
      }
      if (status) {
        if (status == "COMPLETED" && !this.isSendFaxAdmin) {
          queryList.push(
            where("status", "in", [
              "COMPLETED",
              "MAILED",
              "SENT TO 1 OF 2 RECIPIENTS",
              "SENT TO 0 OF 2 RECIPIENTS",
              "UPLOADED TU",
              "UPLOADED EFX",
              "UPLOADED EXP",
            ])
          );
        } else {
          queryList.push(where("status", "==", status));
        }
      }
      if (schedule) queryList.push(where("schedule", "==", schedule));
      if (!this.isDisputeLetters) {
        queryList.push(where("customer", "==", this.$route.params.id));
      } else {
        if (customer) queryList.push(where("customer", "==", customer));
      }
      if (template) queryList.push(where("template", "==", template));
      if (terms.length > 0) {
        queryList.push(where("_search", "array-contains-any", terms));
      }
      if (sortBy) {
        if (sortBy == "created_at") {
          if (!start) {
            queryList.push(orderBy(sortBy, sortDesc ? "desc" : "asc"));
          }
        } else {
          queryList.push(orderBy(sortBy, sortDesc ? "desc" : "asc"));
        }
      } else if (!start) {
        queryList.push(orderBy("created_at", "desc"));
      }

      return {
        countQuery: query(colFaxes, ...queryList),
        pageQuery: query(
          colFaxes,
          ...[...queryList, ...this.getPaginationQuery(this.options)]
        ),
      };
    },
  },
  methods: {
    deleteAllFax() {
      const vm = this;
      vm.$confirm.show({
        message: "Are you sure you want to delete all the selected letters?",
        onConfirm: async (dialogVM) => {
          try {
            let deleteAllFax = httpsCallable(functions, "deleteAllEfaxAction");
            await deleteAllFax({
              ids: this.selected.map((i) => i.id),
            });
            vm.fetchFaxes(vm.options);
            dialogVM.loading = false;
            dialogVM.dialog = false;
          } catch (error) {
            dialogVM.loading = false;
            dialogVM.dialog = false;
            vm.isLoading = false;
            vm.notifyError(error.message);
          }
        },
      });
    },
    getStatus({ status, fax_list }) {
      let completed = null;
      if (fax_list) {
        completed = fax_list.find((i) => i.status == "COMPLETE");
      }
      if (this.isSendFaxAdmin) return status;
      let completedStatus = [
        "UPLOADED TU",
        "UPLOADED EFX",
        "UPLOADED EXP",
        "MAILED",
      ];
      if (completedStatus.indexOf(status) != -1) return "COMPLETED";
      if (completed != null && status == "SENDING") return "COMPLETED";
      return status.indexOf("SENT TO") != -1 ? "COMPLETED" : status;
    },

    getErrorMessage({ status, error_message }) {
      return status == "FAILED" ? error_message : "";
    },

    onFilterApply(event) {
      this.form = event;
      let options = this.$refs.table.options;
      this.$refs.table.options = { ...options, page: 1 };
    },
    getStatusColor(val) {
      return faxStatusColors[val] || "";
    },

    async fetchFaxes(options) {
      const vm = this;
      try {
        if (vm.loading) return;
        vm.options = options;
        vm.loading = true;
        vm.selected = [];
        vm.list = [];
        this.$refs.table.selected = [];
        let page = vm.options.page;
        let { countQuery, pageQuery } = vm.query;
        if (page == 1) {
          vm.total = (await getCountFromServer(countQuery)).data().count;
        }
        let docs = (await getDocs(pageQuery)).docs;
        vm.firstVisible = docs[0];
        vm.lastVisible = docs[docs.length - 1];
        vm.list = mapDocs(docs).map((i) =>
          transformDisputeLetters(i, this.faxTemplates, this.faxSchedules)
        );
        vm.currentPage = page;
        vm.loading = false;
      } catch (error) {
        console.log(error);
        vm.handleError(error);
      }
    },
    async fetchDropdowns() {
      try {
        this.isLoading = true;
        let scheduleQuery = query(
          colFaxSchedules,
          orderBy("schedule_name", "asc")
        );
        let templateQuery = query(
          colFaxTemplates,
          orderBy("template_name", "asc")
        );
        this.faxSchedules = mapDocs((await getDocs(scheduleQuery)).docs);
        this.faxTemplates = mapDocs((await getDocs(templateQuery)).docs);
        this.faxSchedules.unshift({ schedule_name: "N/A", value: "" });
        this.isLoading = false;
      } catch (e) {
        this.handleError(e);
      }
    },
  },
  async mounted() {
    this.fetchDropdowns();
  },
};
</script>
<style></style>
